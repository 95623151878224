import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import config from "../config";

const ProtectedRoute = ({component: Component, ...rest}) =>
{
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    if(!isAuthenticated)
    {
        localStorage.clear();
        window.location.href = `${config.server_host}/login`;
        return (<></>);
    }

    return(
        <Route {...rest} render={props =>
            {
                if(true)
                {
                    return <Component {...props} />;
                }
            }}
        />
    );
};

export default ProtectedRoute;
import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;

interface INews
{
    id?: number,
    title: string,
    url: string,
    image: string,
    publishDate: string,
    category?: string,
    summary?: string,
    newsImportId?: number,
    newsImport?: INewsImport
}
interface INewsImport
{
    id?: number,
    error?: string,
    comments?: string,
    newsSourceId?: number,
    newsSource?: INewsSource
}
interface INewsSource
{
    id?: number,
    name: string,
    logo: string,
    kind: string,
    disabled: boolean
}
export type NewsResponse =
{
    totalRecords: number,
    news: NewsType[]
}

export type NewsType = INews;
export type NewsImportType = INewsImport;
export type NewsSourceType = INewsSource;

class NewsService
{
    public getNews = async (page: number, itemsPerPage: number, searchTerm: string, sources: string[]): Promise<NewsResponse> =>
    {
        let url: string = `${proxy}/news?page=` + page + '&itemsPerPage=' + itemsPerPage;
        if(searchTerm !== '')
        {
            url += '&searchTerm=' + searchTerm;
        }
        if(sources.length > 0)
        {
            url += '&sources=' + sources.join(",");
        }
        const req = await axios.get(url)
        return req.data
    }
    public getNewsByIds = async (page: number, itemsPerPage: number, ids: number[]): Promise<NewsResponse> =>
    {
        let url: string = `${proxy}/news?page=` + page + '&itemsPerPage=' + itemsPerPage;
        if(ids.length > 0)
        {
            url += '&ids=' + ids.join(",");
        }
        const req = await axios.get(url)
        return req.data
    }
    public getNewsSources = async (): Promise<NewsSourceType[]> =>
    {
        let url: string = `${proxy}/newsSources`;
        const req = await axios.get(url)
        return req.data
    }
}

const news = new NewsService();
export default news;
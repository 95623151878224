import React, { useContext } from 'react'
import { Callout, DirectionalHint, INavLink } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { DefaultButton } from 'office-ui-fabric-react';
import { registerIcons } from '@uifabric/styling';
import { Persona, PersonaSize, PersonaPresence } from 'office-ui-fabric-react/lib/Persona';
import { Nav, INavStyles, INavLinkGroup } from '@fluentui/react/lib/Nav';
import config from "../config";
import styled from "styled-components";
import Auth from '../Auth/Auth';
import { ThemeContext } from '@fluentui/react-theme-provider';
import axios from 'axios';
import { useHistory } from 'react-router';

type Props = {user: any}

const PersonComponent = styled.div`
    box-sizing: border-box;
    border-radius: 32px;
    min-width: 200px;
    height: 48px;
    margin-top: 8px !important;
    padding-right: 8px;
    border: ${(props) => // @ts-ignore
        `1px solid ${props.theme.palette.tertiary}`};
    > *
    {
        display: inline-flex;
        vertical-align: middle;
        margin: 7px 0 7px 7px;
        @media (width: 640px)
        {
            width: 83%
        }
        @media (min-width: 641px) and (max-width: 800px)
        {
            width: 87%;
        }
        @media (min-width: 1025px) and (max-width: 4000px)
        {
            width: 87%;
        }
    }
    button
    {
        position: relative;
        z-index: 2000;
        box-sizing: border-box;
        line-height: 26px;
        height: 32px;
        min-width: unset !important;
        min-height: unset !important;
        width: 10%;
        margin-left: 0 !important;
        border-radius: 16px;
        border: ${(props) => // @ts-ignore
        `1px solid ${props.theme.palette.tertiary}`} !important;
        cursor: pointer;
        padding: 0 7.5px;

        i.ironia-icon:after
        {
            font-size: 17px;
        }
    }
    .ms-Persona
    {
        cursor: default;
        @media (min-width: 1366px) and (max-width: 1919.99px)
        {
            width: 87%;
        }
        .ms-Persona-coin
        {
            .ms-Persona-imageArea
            {
                .ms-Persona-initials
                {
                    background: #D9E6FA;
                    span
                    {
                        color: ${(props) => // @ts-ignore
                        `${props.theme.palette.darkBorder}`};
                    }
                }
            }
        }
        .ms-Persona-details
        {
            min-width: 102px;
            cursor: default;
            @media only screen and (min-width: 641px) and (max-width: 800px)
            {
                min-width: 210px;
            }
            @media only screen and (min-width: 801px) and (max-width: 1023.99px)
            {
                min-width: 50%;
            }
            .ms-Persona-primaryText
            {
                @media only screen and (min-width: 641px) and (max-width: 800px)
                {
                    max-width: 210px;
                }
                @media only screen and (width: 768px)
                {
                    max-width: 260px;
                }
                @media only screen and (min-width: 800px) and (max-width: 1024px)
                {
                    max-width: 290px;
                }
                @media only screen and (min-width: 1366px) and (max-width: 1919.99px)
                {
                    max-width: 100%;
                }
                .ms-TooltipHost
                {
                    color: ${(props) => // @ts-ignore
                    `${props.theme.palette.neutralPrimary} !important`};
                }
            }
            .ms-Persona-secondaryText
            {
                max-width: 16em;
                .ms-TooltipHost
                {
                    color: ${(props) => // @ts-ignore
                    `${props.theme.palette.darkBorder} !important`}
                }
            }
        }
    }
    .ms-layer 
    {
        display: none;
    }
`;

const PersonStatus: React.FC<Props> = ({ user }) =>
{
    registerIcons(
    {
        icons:
        {
            'frameworkAgreement': <i className="ironia-icon receipt" />,
            'ironiaDocumentation': <i className="ironia-icon documentation" />,
            'profile': <i className="ironia-icon profile" />,
            'finishedOperations': <i className="ironia-icon finished-operations-2" />,
            'lockOpen': <i className="ironia-icon lock-open" />,
            'bank': <i className="ironia-icon bank" />,
            'information': <i className="ironia-icon information" />,
            'logout': <i className="ironia-icon logout" />
        }
    });
    const theme = useContext(ThemeContext);
    const history = useHistory();
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');
    const labelId = useId('callout-label');
    const descriptionId = useId('callout-description');

    const doLogout = async (event: any) =>
    {
        event.preventDefault();
        await axios.get(`${config.server_host}/oauth/revoke`, {withCredentials: true});
        Auth.logout();
        window.location.replace(`${config.server_host}/login`);
    }
    const navStyles: Partial<INavStyles> =
    {
        root:
        {
            boxSizing: 'border-box',
            border: '1px solid #eee',
            overflowY: 'auto',
        },
        link:
        {
            whiteSpace: 'normal',
            lineHeight: 'inherit',
        },
        chevronIcon: {
            fontSize: "20px"
        }
    };
    const navLinkGroups: INavLinkGroup[] = [
    {
        links:
        [
            {
                key: '/profile/contract/frameworkAgreement',
                icon: 'frameworkAgreement',
                name: 'Condiciones Generales',
                url: '/profile/contract/frameworkAgreement'
            },
            {
                key: '/profile/documentation',
                icon: 'ironiaDocumentation',
                name: 'Documentación',
                url: '/profile/documentation'
            },
            {
                key: '/profile/ownership',
                iconProps: { iconName: 'profile' },
                name: 'Titularidad del contrato',
                url: '/profile/ownership'
            },
            {
                key: '/profile/subscription',
                iconProps: { iconName: 'finishedOperations' },
                name: 'Planes contratados',
                url: '/profile/subscription'
            },
            {
                key: '/password/change',
                iconProps: { iconName: 'lockOpen' },
                name: 'Cambiar contraseña y MFA',
                url: '/password/change',
                onClick: (event: any) => { event.preventDefault(); window.location.href = config.server_host + '/password/change' }
            },
            {
                key: '/profile/bank-accounts',
                iconProps: { iconName: 'bank',  styles: { root: { fontSize: '24px' } }  },
                name: 'Cuentas Corrientes',
                url: '/profile/bank-accounts',
            },
            {
                key: '/logout',
                iconProps: { iconName: 'logout' },
                name: 'Cerrar sesión',
                url: '/logout',
                onClick: (event: any) => doLogout(event)
            },
        ],
    },];

    if(user.memberCode === undefined || user.memberCode === null || user.memberCode === "")
    {
        return(
            <PersonComponent className='persona-component' theme={theme}>
                <Persona imageInitials={user.initials}
                    text={user.fullName}
                    showSecondaryText={false}
                    secondaryText={user.memberCode}
                    size={PersonaSize.size32}
                    presence={PersonaPresence.online}
                />
                <DefaultButton id={buttonId} onClick={toggleIsCalloutVisible}>
                    <i className="ironia-icon keyboard-arrow-down" />
                </DefaultButton>
                {isCalloutVisible ?
                (
                    <Callout ariaLabelledBy={labelId}
                        ariaDescribedBy={descriptionId}
                        role="alertdialog"
                        target={`#${buttonId}`}
                        onDismiss={toggleIsCalloutVisible}
                        setInitialFocus
                        gapSpace={-10}
                        calloutWidth={312}
                        directionalHint={DirectionalHint.bottomRightEdge}
                    >
                        <Persona imageInitials={user.initials}
                            text={user.fullName}
                            showSecondaryText={false}
                            secondaryText={user.memberCode}
                            className="big header"
                            // @ts-ignore
                            size={PersonaSize.size64}
                        />
                        <Nav ariaLabel="Menú de usuario"
                            className="profile-menu"
                            styles={navStyles}
                            groups={navLinkGroups}
                            selectedKey={history.location.pathname}
                            onLinkClick=
                            {
                                (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) =>
                                {
                                    if(ev)
                                    {
                                        ev.nativeEvent.preventDefault();
                                    }
                                    if(item && item.url)
                                    {
                                        history.push(item.url);
                                        toggleIsCalloutVisible();
                                    }
                                }
                            }
                        />
                    </Callout>
                ) : null}
            </PersonComponent>
        );
    }
    else
    {
        return(
            <PersonComponent className='persona-component' theme={theme}>
                <Persona imageInitials={user.initials}
                    text={user.fullName}
                    showSecondaryText={true}
                    secondaryText={user.memberCode}
                    size={PersonaSize.size32}
                    presence={PersonaPresence.online}
                />
                <DefaultButton id={buttonId} onClick={toggleIsCalloutVisible}>
                    <i className="ironia-icon keyboard-arrow-down" />
                </DefaultButton>
                {isCalloutVisible ?
                (
                    <Callout ariaLabelledBy={labelId}
                        ariaDescribedBy={descriptionId}
                        role="alertdialog"
                        target={`#${buttonId}`}
                        onDismiss={toggleIsCalloutVisible}
                        setInitialFocus
                        gapSpace={-10}
                        calloutWidth={312}
                        directionalHint={DirectionalHint.bottomRightEdge}
                    >
                        <Persona imageInitials={user.initials}
                            text={user.fullName}
                            showSecondaryText={true}
                            secondaryText={user.memberCode}
                            className="big header"
                            // @ts-ignore
                            size={PersonaSize.size64}
                        />
                        <Nav ariaLabel="Menú de usuario"
                            className="profile-menu"
                            styles={navStyles}
                            groups={navLinkGroups}
                            selectedKey={history.location.pathname}
                            onLinkClick=
                            {
                                (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) =>
                                {
                                    if(ev)
                                    {
                                        ev.nativeEvent.preventDefault();
                                    }
                                    if(item && item.url)
                                    {
                                        history.push(item.url);
                                        toggleIsCalloutVisible();
                                    }
                                }
                            }
                        />
                    </Callout>
                ) : null}
            </PersonComponent>
        );
    }
};

export default PersonStatus;
import { AxiosInstance, AxiosRequestConfig } from "axios";
import Auth from '../Auth/Auth';
import config from "../config";
import axios from "axios";

const onRequest = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> =>
{
    if (document.location.pathname.startsWith("/iframe/")
        || document.location.pathname.startsWith("/api/")
    ) {
        return Promise.resolve(config);
    }

    let userProfile: any = Auth.getUserProfile();
    if(userProfile?.credentials?.expires_at !== undefined)
    {
        const currentDate: number = Date.now();
        const expireDate: number = userProfile.credentials.expires_at;
        if(currentDate >= expireDate)
        {
            await refreshAccessToken();
            userProfile = Auth.getUserProfile();
        }
        config.headers.Authorization = `Bearer ${userProfile.credentials.access_token}`
    }
    return Promise.resolve(config);
}

const refreshAccessToken = async () =>
{
    const axiosNoAuth = axios.create();
    const userProfile: any = Auth.getUserProfile();
    const response: any = await axiosNoAuth.get(`${config.server_host}/oauth/refresh-token`, {withCredentials: true});

    if(response.data.refresh_token !== undefined)
    {
        userProfile.credentials = response.data;
        Auth.setUserProfile(userProfile);
    }
    else
    {
        window.location.href = `${config.server_host}/login`;
    }
    return response;
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance
{
    axiosInstance.interceptors.request.use(onRequest);
    return axiosInstance;
}
import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
import { ISignature } from "./SignaturesService";
import { UserIdentityType } from "./UserIdentitiesService";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;

export interface Fund
{
    allFundsId: number,
    allfundsId?: string,
    asset: string,
    amount: number,
    available: boolean,
    averageScore: number,
    benchmark: string,
    benchmarkFinametrixId: string,
    buysCloseTime: string,
    category: string,
    classCode: string,
    cleanShare: boolean,
    complexity: boolean,
    currency: string,
    currencyHedge: boolean,
    documents: Documents[],
    family: string,
    finametrixId: string,
    geoArea: string,
    geoZone: string,
    income: boolean,
    indexed: boolean,
    isin: string,
    liquidationDaysOnBuys: number,
    liquidationDaysOnSells: number,
    managementFee: number,
    manager: string,
    minimumAdditionalInvestment: number,
    minimumInitialInvestment: number,
    morningStarCategoryId: string,
    name: string,
    noMin: boolean,
    ongoingCharges: number,
    performanceFee: number,
    purchaseFee: number,
    readonly: boolean,
    rebate: number,
    sellsCloseTime: string,
    shareClassTypeSpain: string,
    subcategory: string,
    switchable: boolean,
    swtiches: string,
    ucits: boolean
}
export interface Documents
{
    name: string,
    type: string,
    url: string
}
export interface CartModel
{
    statusCode?: number,
    allAmount: number,
    isReadonly: boolean,
    funds: Fund[],
    userId: number,
    savingPlanName: string
}
export interface CartOrders
{
    userId: number,
    userIdentityId?: number,
    cartId?: string,
    data: CartOrderInfo[]
}
export interface CartOrderInfo
{
    signatureId: number,
    instrumentId: string,
    hasDisclaimerTime: boolean,
    values:
    {
        amountType: string,
        type: string,
        amount: number,
        fund: string
    }
}
export interface InespayData
{
    reference: string,
    subject: string,
    amount: string,
    isFundPurchase: boolean,
    userId?: number,
    cartId: string
}
export interface CartSignatureModel
{
    signatures: ISignature[],
    userIdentity: UserIdentityType
}
export interface ApiTransferOrder
{
    transferGuid: string,
    hasDisclaimerTime: number
}
class ApiService
{
    public getCartFunds = async (cartId: string): Promise<any> =>
    {
        let url: string = `${proxy}/api/GetCartFunds/${cartId}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public getUserIdentities = async (signatureGuid: string): Promise<any> =>
    {
        let url: string = `${proxy}/api/GetUserIdentitiesBySignatureGUID/${signatureGuid}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public getContractSignatures = async (signatureGuid: string): Promise<any> =>
    {
        let url: string = `${proxy}/api/GetContractSignatures/${signatureGuid}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public patchContractSignatures = async (contractId: number): Promise<any> =>
    {
        let url: string = `${proxy}/api/contracts/${contractId}/signatures`;
        const req = await axios.patch<any>(url);
        return req.data;
    }
    public patchSavingPlan = async (savingPlanId: number): Promise<any> =>
    {
        let url: string = `${proxy}/api/PatchSavingPlan/${savingPlanId}`;
        const req = await axios.patch<any>(url);
        return req.data;
    }
    public getCartSignatures = async (cartId: string): Promise<any> =>
    {
        let url: string = `${proxy}/api/CreateCartSignatures/${cartId}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public refreshSignature = async (signatures: ISignature[]): Promise<ISignature[]> =>
    {
        let url: string = `${proxy}/api/RefreshSignatureCode`;
        const req = await axios.post(url, signatures, {headers: {"content-type": "application/json"}});
        return req.data;
    }
    public createOrdersAndPayments = async (data: CartOrders): Promise<InespayData> =>
    {
        let url: string = `${proxy}/api/CartOrders`;
        const req = await axios.patch(url, data);
        return req.data;
    }
    public postInespay = async (data: InespayData): Promise<any> =>
    {
        let url: string = `${proxy}/api/ApiInespay`;
        const req = await axios.post(url, data);
        return req.data;
    }
    public putSignature = async (data: any): Promise<ISignature[]> =>
    {
        let url: string = `${proxy}/api/PutSignature`;
        const req = await axios.put(url, data);
        return req.data;
    }
    public getExternalTransferData = async (transferGuid: any): Promise<any> =>
    {
        let url: string = `${proxy}/api/GetExternalData/${transferGuid}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public getInternalTransferData = async (transferGuid: any): Promise<any> =>
    {
        let url: string = `${proxy}/api/GetInternalData/${transferGuid}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public getMultiTransferData = async (transferGuid: any): Promise<any> =>
    {
        let url: string = `${proxy}/api/GetMultiTransferData/${transferGuid}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public sendSignatureCode = async (transferGuid: any): Promise<any> =>
    {
        let url: string = `${proxy}/api/SendSignatureCode`;
        const req = await axios.post(url, transferGuid);
        return req.data;
    }
    public postTransfer = async (model: ApiTransferOrder): Promise<boolean> =>
    {
        let url: string = `${proxy}/api/ApiTransferOrder`;
        const req = await axios.post(url, model);
        return req.data;
    }
}

const Api = new ApiService();
export default Api;
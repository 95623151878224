import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;

interface IElements
{
    fire: number,
    earth: number,
    air: number,
    water: number,
    ia?: number
}
export type Elements = IElements;
interface IInespay
{
    subject: string,
    amount: string,
    reference: string,
    frequency?: string,
    account?: string,
    startDate?: string,
    endDate?: string,
    subscription?: boolean,
    renovation?: boolean,
    oldSubscriptionId?: number,
    initialContribution?: number,
    managedPortfolioId?: number,
    bmePortfolioId?: number,
    usersSubscriptionId?: number,
    subscriptionId?: number,
    elements?: Elements,
    isIA?: boolean,
    userId?: number,
    isPlanRenewalBeforeExpiration?: boolean,
    savingsPlanId?: number,
    contractsElements?: any[]
}
interface IBank
{
    bankId: string,
    name: string,
    bankCodes: string[],
    country: string,
    enabled: boolean,
    enabledPeriodicPayment: boolean,
    frequencyPeriodicPayment: string[],
    bankGroupId: string,
    bankGroupName: string
}
interface IBankPeriodicContribution
{
    bankId: string,
    name: string,
    frequencyPeriodicPayment: string[]
}
interface IInespayNoPrice
{
    isError: boolean,
    statusMessage: string
}
export type InespayNoPrice = IInespayNoPrice;
export type InespayType = IInespay;
export type BankType = IBank;
export type BankPeriodicContribution = IBankPeriodicContribution;

class InespayService
{
    public getBanks = async (): Promise<BankType[]> =>
    {
        let url: string = `${proxy}/inespay/banks`;
        const req = await axios.get(url);
        return req.data;
    }
    public postInespay = async (inespay: Partial<InespayType>): Promise<InespayType> =>
    {
        let url: string = `${proxy}/inespay`;
        const req = await axios.post(url, inespay);
        return req.data;
    }
    public postNoPriceInespay = async (inespay: Partial<InespayType>): Promise<InespayNoPrice> =>
    {
        let url: string = `${proxy}/inespayNoPrice`;
        const req = await axios.post(url, inespay);
        return req.data;
    }
    public postCancelPeriodicContribution = async (periodicContributionId: number): Promise<any> =>
    {
        let url: string = `${proxy}/inespay/cancelPeriodicContributions/${periodicContributionId}`;
        const req = await axios.post(url);
        return req.data;
    }
}

const inespay = new InespayService();
export default inespay;
import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;

interface IStatusBar
{
    id: number,
    date: Date,
    canDelete: boolean,
    text: string,
    type: string,
    severityOrder: number,
    isGeneralNotification: boolean
}
export type StatusBarModel = IStatusBar;

interface INotification
{
    userId: number
}

export type NotificationModel = INotification;

class StatusBarService
{
    public GetAllStatusBarEnabled = async(userId: number): Promise<StatusBarModel[]> =>
    {
        let url: string = `${proxy}/GetAllStatusBarEnabled/${userId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public setReadNotification = async(model: NotificationModel): Promise<boolean> =>
    {
        let url: string = `${proxy}/SetReadNotification`;
        const req = await axios.post(url, model);
        return req.data;
    }
}

const StatusService = new StatusBarService();
export default StatusService;
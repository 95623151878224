import ReactDOM from 'react-dom';
import { IntlProvider } from "react-intl";
import Spanish from './translations/es.json';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { store } from "./store";
import config from "./config";
import Rutas from './rutas/Rutas';

ReactDOM.render(
    <Provider store={store}>
        <Rutas>
            <IntlProvider locale='es' messages={Spanish} />
        </Rutas>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if(process.env.NODE_ENV !== 'development')
{
    (function (c, l, a, r, i, t, y)
    {
        c[a] = c[a] || function (){(c[a].q = c[a].q || []).push(arguments)};
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", config.clarity_key);
}
import { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { RootStateOrAny, useSelector } from "react-redux";
import App from "../App";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import FrameRoute from "./FrameRoute";
///Zona Planes de Cartera
import SavingsPlans from "../components/SavingsPlans/SavingsPlans";
import NewSavingsPlan from "../components/SavingsPlans/NewSavingsPlan";
import SaverSavingsPlan from "../components/SavingsPlans/SaverSavingsPlan";
import NewSaverSavingsPlan from "../components/SavingsPlans/NewSaverSavingsPlan";
import ExperienceSavingsPlan from "../components/SavingsPlans/ExperienceSavingsPlan";
import ExperienceSavingsPlanSameIdentities from "../components/SavingsPlans/ExperienceSavingsPlanSameIdentities";
import ExperienceSavingsPlanDifferentIdentities from "../components/SavingsPlans/ExperienceSavingsPlanDifferentIdentities";
import NewExperienceSavingsPlan from "../components/SavingsPlans/NewExperienceSavingsPlan";
import SavingsPlanSelectIdentities from "../components/SavingsPlans/SavingsPlanSelectIdentities";
import ManagementAuthorizationContract from "../components/ManagementAuthorization/ManagementAuthorizationContract";
import ManagementAuthorizationConfirm from "../components/ManagementAuthorization/ManagementAuthorizationConfirm";
///Zona Productos Complejos
import ComplexProductAgreement from "../screen/ComplexProducts/ComplexProductAgreement";
import ComplexProductConfirm from "../screen/ComplexProducts/ComplexProductConfirm";
import SuitabilityTestListForComplexProduct from "../screen/ComplexProducts/SuitabilityTestListForComplexProduct";
import SuitabilityTestForComplexProduct from "../screen/ComplexProducts/SuitabilityTestForComplexProduct";
import SuitabilityTestConfirmForComplexProduct from "../screen/ComplexProducts/SuitabilityTestConfirmForComplexProduct";
//import ExperiencedPage from "../screen/Experienced/ExperiencedPage";
///Zona Portfolio
import Portfolio from "../screen/Portfolios/Summary/Portfolio";
import PortfolioOperations from "../screen/Portfolios/Operations/PortfolioOperations";
import PortfolioFiscalReport from "../screen/Portfolios/FiscalReport/FiscalReport";
import PortfolioOrders from "../screen/Portfolios/Orders/PortfolioOrders";
import OrderIncidence from "../screen/Portfolios/Orders/OrderIncidence/OrderIncidence";
import SharedPortfolio from "../screen/Portfolios/SharedPortfolios/SharedPortfolio";
import SharedPortfolioContract from "../screen/Portfolios/SharedPortfolios/SharedPortfolioContract";
import SharedPortfolioConfirm from "../screen/Portfolios/SharedPortfolios/SharedPortfolioConfirm";
import { SharedPortfoliosRankingScreen } from "../screen/SharedPortfoliosRanking";
import BalancerOrdersConfirmation from "../screen/Portfolios/Balancer/BalancerOrdersConfirmation";
///Zona Perfil
import { FrameworkAgreement } from '../screen/Profile/Contract/FrameworkAgreement';
import { DocumentationProfile } from "../screen/Profile/Documentation/components/Index";
import { OwnershipProfile } from "../screen/Profile/Ownership/components/Index";
import { SubscriptionProfile } from "../screen/Profile/Subscription/components/Index";
//import OfficialDocumentation from "../screen/Profile/OfficialDocumentation";
import Belender from "../screen/Profile/OfficialDocumentation/BelenderIframe";
///Zona Orders, Traspasos y Reembolsos y Firmas
import { PurchaseTransferScreen } from "../screen/PurchaseTransferScreen";
import { ManagementTransfer } from "../screen/ManagementTransfer/ManagementTransfer";
import { ManagementTransferScreen } from "../screen/ManagementTransfer/ManagementTransferScreen";
import { ManagementTransferOrdersScreen } from "../screen/ManagementTransfer/ManagementTransferOrdersScreen";
import { AlchemyTransfer } from "../screen/AlchemyTransfer/AlchemyTransfer";
import { AlchemyTransferScreen } from "../screen/AlchemyTransfer/AlchemyTransferScreen";
import { AlchemyTransferOrdersScreen } from "../screen/AlchemyTransfer/AlchemyTransferOrdersScreen";
import { TransferFormScreen } from "../screen/PurchaseTransferScreen/TransferFormScreen";
import { InternalSwitchFormScreen } from "../screen/PurchaseTransferScreen/InternalSwitchFormScreen";
import { InternalSwitchSignatureScreen } from "../screen/PurchaseTransferScreen/InternalSwitchSignatureScreen";
import { SignatureScreen } from "../screen/PurchaseTransferScreen/TransferSignatureScreen";
import { MarketerSwitchFormScreen } from "../screen/PurchaseTransferScreen/MarketerSwitchFormScreen";
import { MarketerSwitchSignatureScreen } from "../screen/PurchaseTransferScreen/MarketerSwitchSignatureScreen";
import { Refunds } from "../screen/Refunds/Refunds";
import RefundsSignature from "../screen/Refunds/components/RefundsSignature";
///Zona Suscripciones
import Subscriptions from "../components/Subscriptions/Subscriptions";
import Subscription from "../components/Subscriptions/Subscription";
import Payment from "../components/Payments/Payment";
import PaymentOk from "../components/Payments/PaymentOk";
import PaymentError from "../components/Payments/PaymentError";
import RePaymentSubscription from "../components/Payments/RePaymentSubscription";
import { MoreExperiencePlans } from "../components/SavingsPlans/MoreExperiencePlans";
import { MoreResuscriptionPlans } from "../components/Payments/MoreResuscriptionPlans";
///Zona Buscador de Fondos
import Instruments from "../components/Instruments";
import FundDetailPage from "../screen/FundDetail/FundDetailPage";
import BuscadorFondos from "../components/Instruments/";
//Zona Otros Buscadores
import SearchManagers from "../components/Buscadores/iFrameGestoras/SearchManagers";
import BuscadorCategoria from "../screen/CategorySearch/index";
//import { BuscadorHipotecas } from "../components/Buscadores/BuscadorHipotecas/BuscadorHipotecas";
import CompanyFinder from "../components/CompanyFinder/";
import SearchNews from "../screen/News/";
import TendsSearch  from "../screen/TendsSearch";
import TrainingCourses  from "../screen/TrainingCourses";
import IronIAExplica from "../screen/IroniaExplica/IroniaExplica";
///Zona Tests Idoneidad y Tests Conveniencia
import * as ConvenienceTest from '../components/ConvenienceTest/index';
import SuitabilityTestList from "../screen/SuitabilityTest/SuitabilityTestList";
import SuitabilityTestScreen from "../screen/SuitabilityTest/SuitabilityTestScreen";
import SuitabilityTestConfirmScreen from "../screen/SuitabilityTest/SuitabilityTestConfirmScreen";
///Zona Home
import { Home, SearchCategoryRankingScreen } from "../screen/";
import PromotionedPage from "../screen/PromotionedLogic/PromotionedPage";
import AsignPromotions from "../screen/PromotionedLogic/Promotion/AsignPromotions";
///Zona Carrito
import { CartScreen } from "../screen/Cart/CartScreen";
import { CartSignatureScreen } from "../screen/Cart/CartSignatureScreen";
///Zona Información Legal
import Legal from "../screen/Legal/LegalInformation";

import Donations from "../screen/Elements/Donations/Donations";

///Zona Elements
import Elements from "../screen/Elements/Elements";
import ElementSignature from "../screen/Elements/ElementSignature";
import ElementSignatureConfirm from "../screen/Elements/ElementSignatureConfirm";
import ElementsIA from "../screen/Elements/ElementsIA";
import ElementsIATransfer from "../screen/Elements/ElementsIATransfer";
import ElementsIARebate from "../screen/Elements/ElementsIARebate";
import FriendsPromotionContract from "../screen/Elements/FriendsPromotion/FriendsPromotionContract";
import FriendsPromotionConfirm from "../screen/Elements/FriendsPromotion/FriendsPromotionConfirm";
import { ElementsFrameworkAgreement } from "../screen/Elements/Contract/ElementsFrameworkAgreement";
///Zona PortfolioModel
import PeriodicContribution from "../screen/PortfolioModel/PeriodicContribution";
import ManagePeriodicContribution from "../screen/PortfolioModel/ManagePeriodicContribution";
///Zona Mapa Configuración
import { MapConfiguration } from "../screen/Map/MapConfiguration";
import { PeriodicSaving } from "../screen/PeriodicSaving/PeriodicSaving";
//Zona Dataverse
import Dataverse from "../screen/Dataverse";
import DataverseReport from "../screen/Dataverse/Report";
import FundRoute from "./FundRoute";
import ErrorComponent from "../screen/Error/ErrorComponent";
import Optimizer from "../screen/Optimizer";
//Zona Api
import ApiCart from "../Api/ApiCart";
import ApiContract from "../Api/ApiContract";
import ApiTripartiteContract from "../Api/ApiTripartiteContract";
import ApiExternalTransfer from "../Api/ApiExternalTransfer";
import ApiInternalTransfer from "../Api/ApiInternalTransfer";
import ApiMultiTransfer from "../Api/ApiMultiTransfer";
//Zona Competitions
import Competitions from "../screen/Competitions";
import Competition from "../screen/Competition";
import OrderCancel from "../screen/Portfolios/Orders/OrderCancelAutomatic/OrderCancelAutomatic";
import PaymentCancelError from "../components/Payments/PaymentCancelError";
import PaymentCancelOk from "../components/Payments/PaymentCancelOk";
import OrderCancelManual from "../screen/Portfolios/Orders/OrderCancelManual/OrderCancelManual";
import OrderCancelAutomatic from "../screen/Portfolios/Orders/OrderCancelAutomatic/OrderCancelAutomatic";
import CompetitionNew from "../screen/CompetitionNew";
import CompetitionParticipate from "../screen/CompetitionParticipate";
import { CurrentAccounts } from "../screen/Profile/CurrentAccounts/components/Index";
import PaymentAccountError from "../components/Payments/PaymentAccountError";
import PaymentAccountNoTitular from "../components/Payments/PaymentAccountNoTitular";

function Rutas(props: any)
{
    const isAuthenticated = useSelector((state: RootStateOrAny) => state.auth.isAuthenticated);

    useEffect(() => {}, [isAuthenticated]);

    return(
		<div>
			<BrowserRouter>
				<Route render={props =>
                (
                    <App>
                        <Switch>
                            {/* Rutas para iFrame (FrameRoute): */}
                            <FrameRoute path="/iframe/savingsPlans/iAmASaver" component={NewSaverSavingsPlan} />
                            <FrameRoute exact path="/iframe/search" component={BuscadorFondos} />
                            <FrameRoute exact path="/iframe/search/category" component={BuscadorCategoria} />
                            <FrameRoute exact path="/iframe/search/ranking" component={SharedPortfoliosRankingScreen} />
                            <FrameRoute exact path="/iframe/search/tends" component={TendsSearch} />
                            <FrameRoute exact path="/iframe/buscador/:manager" component={SearchManagers} />
                            <FrameRoute path="/iframe/:isin/" component={FundDetailPage} />
                            <FrameRoute path="/api/Cart/:cartId" component={ApiCart} />
                            <FrameRoute path="/api/Contract/:guid" component={ApiContract} />
                            <FrameRoute path="/api/TripartiteContract/:guid" component={ApiTripartiteContract} />
                            <FrameRoute path="/api/External/:transferId/:isinOrigin/:isinTarget" component={ApiExternalTransfer} />
                            <FrameRoute path="/api/Internal/:transferId/:isinOrigin/:isinTarget" component={ApiInternalTransfer} />
                            <FrameRoute path="/api/MultiTransfer/:userId/:transferId" component={ApiMultiTransfer} />
                            <FrameRoute exact path="/AsignPromotions" component={AsignPromotions} />

                            {/* Rutas públicas (PublicRoute): */}
                            <PublicRoute exact path="/payment/ok" component={PaymentOk} />
                            <PublicRoute exact path="/payment/error" component={PaymentError} />
                            <PublicRoute exact path="/payment/cancel/ok" component={PaymentCancelOk} />
                            <PublicRoute exact path="/payment/cancel/error" component={PaymentCancelError} />
                            <PublicRoute exact path="/payment/account-error/:idorder" component={PaymentAccountError} />
                            <PublicRoute exact path="/payment/account-no-titular/:data" component={PaymentAccountNoTitular} />
                            <PublicRoute exact path="/Legal" component={Legal} />

                            {/* Rutas privadas (ProtectedRoute): */}
                            <ProtectedRoute exact path="/" component={Home} />
                            <ProtectedRoute exact path="/promotioned" component={PromotionedPage} />
                            <ProtectedRoute exact path="/search-category-ranking" component={SearchCategoryRankingScreen} />
                            <ProtectedRoute exact path="/cart" component={CartScreen} />
                            <ProtectedRoute exact path="/cart/signature" component={CartSignatureScreen} />
                            <ProtectedRoute exact path="/portfolio" component={Portfolio} />
                            <ProtectedRoute exact path="/portfolio/operations" component={PortfolioOperations} />
                            <ProtectedRoute exact path="/portfolio/orders" component={PortfolioOrders} />
                            <ProtectedRoute exact path="/portfolio/orders/:finametrixId" component={OrderIncidence} />
                            <ProtectedRoute exact path="/portfolio/orders/cancel/a/:finametrixId" component={OrderCancelAutomatic} />
                            <ProtectedRoute exact path="/portfolio/orders/cancel/m/:finametrixId" component={OrderCancelManual} />
                            <ProtectedRoute exact path="/portfolio/fiscalReport" component={PortfolioFiscalReport} />
                            <ProtectedRoute exact path="/ModelPortfolio/PeriodicContributions/:id" component={PeriodicContribution} />
                            <ProtectedRoute exact path="/ModelPortfolio/ManagePeriodicContributions/:ModelPortfolioId" component={ManagePeriodicContribution} />
                            <ProtectedRoute exact path="/sharedPortfolios/:sharedPortfolioId/contract" component={SharedPortfolioContract} />
                            <ProtectedRoute exact path="/sharedPortfolios/:sharedPortfolioId/confirm" component={SharedPortfolioConfirm} />
                            <ProtectedRoute exact path="/sharedPortfolios/:sharedPortfolioId" component={SharedPortfolio} />
                            <ProtectedRoute exact path="/portfolio/refunds" component={Refunds} />
                            <ProtectedRoute exact path="/portfolio/refunds/signature" component={RefundsSignature} />
                            <ProtectedRoute exact path="/portfolio/balancer" component={BalancerOrdersConfirmation} />
                            <ProtectedRoute exact path="/Optimizer" component={Optimizer} />
                            {/*<ProtectedRoute path="/sharedPortfoliosRanking" component={SharedPortfoliosRankingScreen} />*/}
                            <ProtectedRoute exact path="/subscriptions" component={Subscriptions} />
                            <ProtectedRoute exact path="/subscription/:subscriptionId/:managedPortfolioId?" component={Subscription} />
                            <ProtectedRoute exact path="/Repayment" component={RePaymentSubscription} />
                            <ProtectedRoute exact path="/payment" component={Payment} />
                            <ProtectedRoute exact path="/savingsPlans" component={SavingsPlans} />
                            <ProtectedRoute exact path="/savingsPlans/new" component={NewSavingsPlan} />
                            <ProtectedRoute exact path="/savingsPlans/iAmASaver/:savingsPlanId" component={SaverSavingsPlan} />
                            <ProtectedRoute exact path="/savingsPlans/iAmASaver" component={NewSaverSavingsPlan} />
                            <ProtectedRoute exact path="/savingsPlans/iHaveExperience/:savingsPlanId" component={ExperienceSavingsPlan} />
                            {/* <ProtectedRoute path="/savingsPlans/experienced" component={ExperiencedPage} /> */}
                            <ProtectedRoute exact path="/savingsPlans/iHaveExperience" component={NewExperienceSavingsPlan} />
                            <ProtectedRoute exact path="/savingsPlans/sameIdentities" component={ExperienceSavingsPlanSameIdentities} />
                            <ProtectedRoute exact path="/savingsPlans/differentIdentities" component={ExperienceSavingsPlanDifferentIdentities} />
                            <ProtectedRoute exact path="/morePlans" component={MoreExperiencePlans} />
                            <ProtectedRoute exact path="/Repayment/morePlans" component={MoreResuscriptionPlans} />
                            <ProtectedRoute exact path="/savingsPlans/:savingsPlanId/selectIdentities" component={SavingsPlanSelectIdentities} />
                            <ProtectedRoute exact path="/savingsPlans/:savingsPlanId/managementAuthorizationContract" component={ManagementAuthorizationContract}/>
                            <ProtectedRoute exact path="/savingsPlans/:savingsPlanId/managementAuthorizationConfirm" component={ManagementAuthorizationConfirm} />
                            <ProtectedRoute exact path="/search/category" component={BuscadorCategoria} />
                            <ProtectedRoute exact path="/search" component={BuscadorFondos} />
                            <ProtectedRoute exact path="/search/business" component={CompanyFinder} />
                            <ProtectedRoute exact path="/search/news" component={SearchNews} />
                            <ProtectedRoute exact path="/search/trainingCourses" component={TrainingCourses} />
                            {/* <ProtectedRoute exact path='/search/mortgage' component={BuscadorHipotecas} /> */}
                            {/*<ProtectedRoute exact path='/search/ranking' component={WalletRanking} />*/}
                            <ProtectedRoute exact path="/search/ranking" component={SharedPortfoliosRankingScreen} />
                            <ProtectedRoute exact path="/search/tends" component={TendsSearch} />
                            <ProtectedRoute exact path="/competitions/new" component={CompetitionNew} />
                            <ProtectedRoute exact path="/competitions/join/:competitionId" component={CompetitionParticipate} />
                            <ProtectedRoute exact path="/search/IronIAExplica" component={IronIAExplica} />
                            <ProtectedRoute exact path="/Elements" component={Elements} />
                            <ProtectedRoute exact path="/Elements/Signature" component={ElementSignature} />
                            <ProtectedRoute exact path="/Elements/Signature/Confirmation" component={ElementSignatureConfirm} />
                            <ProtectedRoute exact path="/elements/sharedPortfolios" component={SharedPortfoliosRankingScreen} />
                            <ProtectedRoute exact path="/ElementsIA" component={ElementsIA} />
                            <ProtectedRoute exact path="/ElementsIA/Transfer" component={ElementsIATransfer} />
                            <ProtectedRoute exact path="/ElementsIA/Rebate" component={ElementsIARebate} />
                            <ProtectedRoute exact path="/elements/friends-promotion/contract" component={FriendsPromotionContract} />
                            <ProtectedRoute exact path="/elements/friends-promotion/confirm" component={FriendsPromotionConfirm} />
                            <ProtectedRoute exact path="/elements/frameworkAgreement" component={ElementsFrameworkAgreement} />
                            <ProtectedRoute exact path="/elements/donations" component={Donations} />
                            {/* TODO: Confirmar formato de rutas. Por ahora reemplazo "/" por "-". */}
                            {/* Comentada por duplicacion de screen */}
                            {/* <ProtectedRoute exact path='/Selection' component={Selection} /> */}
                            <ProtectedRoute exact path="/purchase-transfer" component={PurchaseTransferScreen} />
                            <ProtectedRoute exact path="/ManagementTransfer" component={ManagementTransfer} />
                            <ProtectedRoute exact path="/ManagementTransfer/TransferScreen" component={ManagementTransferScreen} />
                            <ProtectedRoute exact path="/ManagementTransfer/OrdersPreview" component={ManagementTransferOrdersScreen} />
                            <ProtectedRoute exact path="/AlchemyTransfer" component={AlchemyTransfer} />
                            <ProtectedRoute exact path="/AlchemyTransfer/TransferScreen" component={AlchemyTransferScreen} />
                            <ProtectedRoute exact path="/AlchemyTransfer/OrdersPreview" component={AlchemyTransferOrdersScreen} />
                            <ProtectedRoute exact path="/distributor-switch" component={MarketerSwitchFormScreen} />
                            <ProtectedRoute exact path="/distributor-switch/signature" component={MarketerSwitchSignatureScreen} />
                            <ProtectedRoute exact path="/internal-switch" component={InternalSwitchFormScreen} />
                            <ProtectedRoute exact path="/internal-switch/signature" component={InternalSwitchSignatureScreen} />
                            <ProtectedRoute exact path="/transfer" component={TransferFormScreen} />
                            <ProtectedRoute exact path="/transfer/signature" component={SignatureScreen} />

                            {/* Comentada tras resolución de conflictos */}
                            {/*<ProtectedRoute path="/compra" component={TabsScreenPorfolio} />*/}
                            <ProtectedRoute exact path="/configurationMap" component={MapConfiguration} />
                            <ProtectedRoute exact path="/periodicSaving" component={PeriodicSaving} />
                            <ProtectedRoute exact path="/belender" component={Belender} />
                            <ProtectedRoute exact path="/dataverse" component={Dataverse} />
                            <ProtectedRoute exact path="/dataverse/report/:reportId" component={DataverseReport} />
                            <ProtectedRoute exact path="/accountHolder/:savingsPlanId" component={ConvenienceTest.ConvenienceTestAccountHolder} />
                            <ProtectedRoute exact path="/accountHolderData/:savingsPlanId/:userIdentityId?" component={ConvenienceTest.ConvenienceTestAccountHolderData} />
                            <ProtectedRoute exact path="/address/:savingsPlanId/:userIdentityId" component={ConvenienceTest.ConvenienceTestAddress} />
                            <ProtectedRoute exact path="/id/:savingsPlanId/:userIdentityId" component={ConvenienceTest.ConvenienceTestID} />
                            <ProtectedRoute exact path="/finance/:savingsPlanId/:userIdentityId" component={ConvenienceTest.ConvenienceTestFinance} />
                            <ProtectedRoute exact path="/contract/:savingsPlanId" component={ConvenienceTest.ConvenienceTestContract} />
                            <ProtectedRoute exact path="/confirm/:savingsPlanId" component={ConvenienceTest.ConvenienceTestConfirm} />
                            <ProtectedRoute exact path="/profile/contract/frameworkAgreement" component={FrameworkAgreement} />
                            <ProtectedRoute exact path="/profile/documentation" component={DocumentationProfile} />
                            <ProtectedRoute exact path="/profile/ownership" component={OwnershipProfile} />
                            <ProtectedRoute exact path="/profile/bank-accounts" component={CurrentAccounts} />
                            <ProtectedRoute exact path="/profile/subscription" component={SubscriptionProfile} />
                            <Route exact path="/profile/changePassword" render={() => (window.location.href = "/profile/changePassswod")} />
                            <ProtectedRoute exact path="/suitabilityTestList/:savingsPlanId" component={SuitabilityTestList} />
                            <ProtectedRoute exact path="/suitabilityTest/:savingsPlanId/:userIdentityId?" component={SuitabilityTestScreen} />
                            <ProtectedRoute exact path="/suitabilityTest/:savingsPlanId/confirm/:userIdentityId" component={SuitabilityTestConfirmScreen} />
                            <ProtectedRoute exact path="/instruments/:id" component={Instruments} />
                            <ProtectedRoute exact path="/instruments2" component={Instruments} />
                            <ProtectedRoute exact path="/savingsPlans/:savingsPlanId/complexProducts/:isin/agreement" component={ComplexProductAgreement} />
                            <ProtectedRoute exact path="/savingsPlans/:savingsPlanId/complexProducts/:isin/confirm" component={ComplexProductConfirm} />
                            <ProtectedRoute exact path="/savingsPlans/:savingsPlanId/complexProducts/:isin/suitabilityTestList" component={SuitabilityTestListForComplexProduct} />
                            <ProtectedRoute exact path="/savingsPlans/:savingsPlanId/complexProducts/:isin/suitabilityTest/:userIdentityId" component={SuitabilityTestForComplexProduct} />
                            <ProtectedRoute exact path="/savingsPlans/:savingsPlanId/complexProducts/:isin/suitabilityTestConfirm/:userIdentityId" component={SuitabilityTestConfirmForComplexProduct} />
                            <ProtectedRoute exact path="/competitions" component={Competitions} />
                            <ProtectedRoute exact path="/competitions/:competitionId" component={Competition} />
                            <FundRoute path="/:isin" component={FundDetailPage} error={ErrorComponent} />
                            <ProtectedRoute exact path="/Error" component={ErrorComponent} />
                        </Switch>
                    </App>
                )}/>
			</BrowserRouter>
		</div>
	);
}
export default Rutas;
import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;


class PromotionsService
{
    public asignPromotions = async (model: any): Promise<boolean> =>
    {
        let url: string = `${proxy}/asignPromotions`;
        const req = await axios.post(url, model);
        return req.data;
    }
}

const PromotionService = new PromotionsService();
export default PromotionService;
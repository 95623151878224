import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;

export type BraindexLoginResponse =
{
    jwt: string;
}
export type BraindexCoursesResponse =
{
    isRefreshed: boolean;
    newTokenValue: string;
    courses: CourseData[]
}

export type CourseData =
{
    id: number;
    name: string;
    slug: string;
    permalink: string;
    featured: boolean;
    short_description: string;
    sku: string;
    price: string;
    regular_price: string;
    sale_price: string;
    price_html: string;
    on_sale: boolean;
    average_rating: string;
    categories: BraindexCategory[]
    images: BraindexImages[]
};
export type BraindexCategory =
{
    id: number;
    name: string;
    slug: string;
}
export type BraindexImages =
{
    id: number;
    name: string;
    src: string;
}

class TrainingCoursesService
{
    public loginBraindex = async (): Promise<BraindexLoginResponse> =>
    {
        let url: string = `${proxy}/trainingCourses/braindex/login`
        const req = await axios.get<BraindexLoginResponse>(url);
        return req.data;
    }
    public getBraindexCourses = async (jwt: string): Promise<BraindexCoursesResponse> =>
    {
        let url: string = `${proxy}/trainingCourses/braindex/courses`
        const req = await axios.get<BraindexCoursesResponse>(url, { headers: { "BraindexJwt": jwt } });
        return req.data;
    }
}

const TrainingCourses = new TrainingCoursesService();
export default TrainingCourses;
import { useEffect, useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import config from '../src/config';
import axios from "axios";
import Cookies from "universal-cookie";

export interface IdleProps
{
    timeout: number
}

const AutoLogoutLogic = (props: IdleProps) =>
{
    //var [exiting, setExiting] = useState(false);

    const onIdle = async () =>
    {
        await axios.get(`${config.server_host}/oauth/revoke`, {withCredentials: true});
        localStorage.clear();
        const cookies = new Cookies();
        cookies.remove("statusBarNotifications", {path: "/"});
        window.location.href = `${config.server_host}/login`;
    }

    const reloadUserCredentials = async () =>
    {
    //     if(!exiting)
    //     {
    //         setExiting(true);
    //         const axiosNoAuth = axios.create();
    //         const userProfile: any = Auth.getUserProfile();
    //         // const response: any = await axiosNoAuth.post(
    //         //     `${config.server_host}/oauth/refresh-token`,
    //         //     { withCredentials: true }
    //         // );

    //         localStorage.clear();

    //         if(response.data.refresh_token !== undefined)
    //         {
    //             userProfile.credentials = response.data;
    //             Auth.setUserProfile(userProfile);
    //             setExiting(true);
    //         }
    //         else
    //         {
    //             window.location.href = `${config.server_host}/login`;
    //         }
    //     }
    }

    useEffect(() =>
    {
        window.addEventListener('beforeunload', reloadUserCredentials);
        return () =>
        {
            window.removeEventListener('beforeunload', reloadUserCredentials);
        }
    });

    const idleTimerRef = useRef<IdleTimer | null>(null);

    return(
        <div>
            <IdleTimer timeout={props.timeout * 1000}
                onIdle={onIdle}
                ref={idleTimerRef as any}
            />
        </div>
    );
}

export default AutoLogoutLogic;
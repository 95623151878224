import axios from "axios";
import currenciesMock from "../mocks/currencies";
import config from "../config";

export const getCurrencies = async () =>
{
    try
    {
        const response = await axios.get(config.funds_service + "/currencies");
        return response.data;
    }
    catch (error)
    {
        return currenciesMock;
    }
};
import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
import { OrderType } from "./OrderService";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;

interface IPayment
{
    id?: number,
    usersSubscriptionId?: number,
    savingsPlanId?: number,
    amount: number,
    reference: string,
    frequency?: string,
    countryBank?: string,
    bank?: string,
    account?: string,
    startDate?: string,
    endDate?: string,
    status: number,
    subject: string,
    platform: string,
    orders?: OrderType[],
    inespayId?: string,
    createdAt?: string
}

export type PaymentType = IPayment

class PaymentService
{
    public createPayment = async (payment: PaymentType): Promise<Partial<PaymentType>> =>
    {
        let url: string = `${proxy}/payments`;
        const req = await axios.post(url, payment);
        return req.data;
    }
    public putPayment = async (paymentId: number, payment: PaymentType): Promise<PaymentType> =>
    {
        let url: string = `${proxy}/payments/${paymentId}`;
        const req = await axios.put(url, payment);
        return req.data;
    }
}

const payment = new PaymentService();
export default payment;
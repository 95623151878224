import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import { setupInterceptorsTo } from "./Interceptors";
import config from "../config";

setupInterceptorsTo(axios);

export interface CancelablePromise<T> extends AxiosPromise<T>
{
    cancel?: () => {};
}

export class Service<T> { // eslint-disable-line @typescript-eslint/no-unused-vars
    /**
     * 
     * @param settings Configuración de llamada Axios.
     * @param baseURL Sólo para pruebas.
     * @returns 
     */
    protected static request<T>(settings: AxiosRequestConfig, baseURL?: string): CancelablePromise<T>
    {
        if(!baseURL)
        {
            baseURL = config.proxy_url;
        }
        if(settings.url)
        {
            settings.url = `${baseURL}${settings.url}`;
        }
        let cancel;
        let promise: CancelablePromise<T> = axios(
        {
            ...settings,
            cancelToken: new axios.CancelToken(c => cancel = c)
        });
        promise.cancel = cancel;
        return promise;
    }
}
import { useEffect, useRef, useState } from "react";
import subscriptionService, { SubscriptionType } from "../../services/SubscriptionService";
import { useLocation } from "react-router-dom";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { MoreResuscriptionPlansItem } from "./MoreResuscriptionPlansItem";
import { ContractsUnderSameSubscription } from "./RePaymentSubscription";
import React from "react";
import { MoreResuscriptionPlansTitle } from "./RePaymentSubscriptionStyle";
import DocumentTitle from "react-document-title";

export const MoreResuscriptionPlans = () =>
{
    const { state } = useLocation<any>();
    const contracts = useRef<ContractsUnderSameSubscription>(state?.contracts);
    const prevSubscription = contracts.current.subscriptionId;
    const limitAmount = contracts.current.subscriptionLimit;
    const isLoading = useRef<boolean>(false);
    const errorMini = useRef<string>("");
    const [subscriptions, setSubscriptions] = useState<SubscriptionType[]>();

    useEffect(() =>
    {
        if(subscriptions === undefined)
        {
            subscriptionService.getSubscriptionsByType("experience").then((response: SubscriptionType[]) =>
            {
                //Lo primero que hacemos es quitarnos IronIA Mes a Mes
                var indexMes: number = response.findIndex((element: SubscriptionType) => element.name === "IronIA Mes a Mes");
                response.splice(indexMes, 1);

                var prevSubscriptionId = [4, 8].includes(prevSubscription) ? prevSubscription : 4;
                var indexSelected: number = response.findIndex((element: SubscriptionType) => element.id === prevSubscriptionId);
                var indexMini: number = response.findIndex((element: SubscriptionType) => element.name === "IronIA Mini");

                if(limitAmount > 0)
                {
                    if(indexSelected === indexMini)
                    {
                        errorMini.current = "Tu cartera actualmente tiene un valor superior a 3000€, por lo que no puedes "
                            + "renovar tu suscripción en IronIA Mini. Selecciona uno de los otros planes para resuscribirte.";
                    }
                    response.splice(indexMini, 1);
                    if(indexSelected >= indexMini)
                    {
                        indexSelected--;
                    }
                }

                var selectedSubscription = response[indexSelected];
                response.splice(indexSelected, 1);
                response.splice(1, 0, selectedSubscription);

                isLoading.current = true;
                setSubscriptions(response);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevSubscription, limitAmount]);

    if(isLoading.current === false)
    {
        return (<Spinner size={SpinnerSize.large} />);
    }
    else
    {
        return(
            <DocumentTitle title = 'IronIA - Renovación de Suscripción'>
                <React.Fragment>
                    <MoreResuscriptionPlansTitle>
                        {subscriptions?.length === 1 &&
                        (
                            <React.Fragment>
                                Esta será tu nueva suscripción
                            </React.Fragment>
                        )}
                        {subscriptions?.length === 2 &&
                        (
                            <React.Fragment>
                                Selecciona la suscripción
                            </React.Fragment>
                        )}
                    </MoreResuscriptionPlansTitle>
                    <div className="row">
                        {subscriptions !== undefined && subscriptions.map((subscription: SubscriptionType, subscriptionIndex: number) =>
                        (
                            <React.Fragment key={subscriptionIndex}>
                                {subscriptions.length === 1 &&
                                (
                                    <React.Fragment key={'sublength1'}>
                                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4" key={'subscriptionIndexA'+subscriptionIndex} />
                                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4" key={'subscriptionIndexB'+subscriptionIndex}>
                                            <MoreResuscriptionPlansItem
                                                key={'sub1'+subscriptionIndex}
                                                contracts={contracts.current}
                                                newSubscription={subscription}
                                                className={'selected'}
                                            />
                                        </div>
                                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4" key={'subscriptionIndexC'+subscriptionIndex} />
                                    </React.Fragment>
                                )}
                                {subscriptions.length === 2 &&
                                (
                                    <React.Fragment key={'sublength2'}>
                                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 ms-xl1" />
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl5" key={'subscriptionIndexAA'+subscriptionIndex}>
                                            <MoreResuscriptionPlansItem
                                                key={'sub2'+subscriptionIndex}
                                                contracts={contracts.current}
                                                newSubscription={subscription}
                                                className={'spaced'}
                                            />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 ms-xl1" />
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </React.Fragment>
            </DocumentTitle>
        );
    }
}
import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
import { ProviderType } from "./ProviderService";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;

export const subscriptionTypes = 
[
    "saver",
    "alchemy",
    "advanced",
    "promotioned",
    "experience"
];

interface ISubscription
{
    id?: number,
    name: string,
    description: string,
    period: string,
    price?: number,
    limit?: number,
    freePeriods?: number,
    advised?: boolean,
    recommended?: boolean,
    actionText?: string,
    subscriptionType: string,
    priceIronia: number,
    priceProvider: number,
    riskLevel: number,
    providerId?: number,
    provider?: ProviderType,
    type?: string,
    limitPeriod?: number,
    isSelected?: boolean
}
interface IUserSubscriptions
{
    subscriptionName: string,
    subscriptionStartDate: string,
    subscriptionEndDate: string,
    subscriptionStatus: string,
    subscriptionPayments: IPaymentsSubscriptions[]
}
interface IPaymentsSubscriptions
{
    subscriptionName: string,
    paymentStartDate: string,
    paymentEndDate: string
}
interface IAccounts
{
    id?: number,
    userid: number,
    holderOrigin: string,
    accountOrigin: string,
    titularityPdf: string
}
interface IAccountsRequest
{
    userId: number,
    holderOrigin: string,
    accountOrigin: string,
}
interface IAccountsAutorized
{
    id?: number,
    userid: number,
    holderOrigin: string,
    accountOrigin: string,
    titularityPdf: string,
    isSelected: boolean,
    savingPlanId: number
}
interface IAccountsUsed
{
    id?: number,
    userid: number,
    holderOrigin: string,
    accountOrigin: string,
    idDebt: string,
    createAt: Date,
    titularityPdf?: string
}
interface IAccountsRequest
{
    userId: number,
    holderOrigin: string,
    accountOrigin: string,
    idDebt?: string,
    createAt?: Date
}

export type AccountsAutorized = IAccountsAutorized;
export type AccountsUsed = IAccountsUsed;
export type SubscriptionType = ISubscription;
export type UserSubscriptionsType = IUserSubscriptions;
export type PaymentsSubscriptionsType = IPaymentsSubscriptions;
export type AccountsType = IAccounts;
export type AccountRequestType = IAccountsRequest;

class SubscriptionService
{
    public getSubscription = async (subscriptionId: number): Promise<ISubscription> =>
    {
        let url: string = `${proxy}/subscriptions/${subscriptionId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getSubscriptions = async (): Promise<ISubscription[]> =>
    {
        let url: string = `${proxy}/subscriptions`;
        const req = await axios.get(url);
        return req.data;
    }
    public getDefaultSubscription = async (): Promise<ISubscription[]> =>
    {
        let url: string = `${proxy}/GetDefaultSubscription`;
        const req = await axios.get(url);
        return req.data;
    }
    public getSubscriptionsByType = async (subscriptionType: string): Promise<ISubscription[]> =>
    {
        let url: string = `${proxy}/subscriptions/getByType/${subscriptionType}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getSubscriptionByUser = async(userId: number): Promise<IUserSubscriptions[]> =>
    {
        let url: string = `${proxy}/subscriptions/getByUserId/${userId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getSubscriptionBySavingPlanId = async(userId: number, savingPlanId: number) : Promise<ISubscription> =>
    {
        let url: string = `${proxy}/subscriptions/GetSubscriptionBySavingPlanId/${savingPlanId}/${userId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getRecommendedSubscription =  async (): Promise<ISubscription> =>
    {
        let url: string = `${proxy}/subscriptions/getRecommendedSubscription`;
        const req = await axios.get(url);
        return req.data;
    }
    public getPromotedSubscriptionGetByUserId = async(userId: number) : Promise<ISubscription> =>
    {
        let url: string = `${proxy}/subscriptions/promoted/getByUserId/${userId}`;
        const req = await axios.get(url);
        return req.data;
    }
}

const subscriptionService = new SubscriptionService();
export default subscriptionService;
import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;

interface IContractDocsVersion
{
    id: number,
    text: string
    version: string,
    contractDocsId: number,
    createdAt: Date,
    updatedAt?: Date
}
export type ContractDocsVersionType = IContractDocsVersion;

class ContractDocsService
{
    public getLastVersion = async (contractDocsName: string): Promise<ContractDocsVersionType> => {
        let url: string = `${proxy}/contractDocs/${contractDocsName}/lastVersion`;
        const req = await axios.get<any>(url);
        return req.data;
    }
}

const contractDocs = new ContractDocsService();
export default contractDocs;
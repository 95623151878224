import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
import { AccountsAutorized, AccountsType, AccountsUsed, SubscriptionType } from "./SubscriptionService";
import { UserIdentityType } from "./UserIdentitiesService";
import { UsersSubscriptionType } from "./UsersSubscriptionService";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;

interface IUser
{
    id?: number;
    druId?: string;
    userIdentities?: UserIdentityType[];
    mainUserIdentity: UserIdentityType;
    usersSubscriptions?: UsersSubscriptionType[];
    finametrixId?: string;
    status?: any;
    email?: string;
    afilliate?: IAfilliate;
    afilliateId?: string;
    createdAt?: string;
    updatedAt?: string;
}
export type UserType = IUser;

interface IAfilliate
{
    id: string;
    name: string;
    email: string;
    trust: boolean;
    notificattionUrlOk?: string;
    notificattionUrlKo?: string;
    createdAt?: string;
    updatedAt?: string;
}

export type Afilliate = IAfilliate;

interface PortfolioItem
{
    bmePortfolio: any;
    finametrixId: string;
    id: number;
    managedPortfolioFinametrixId: number;
    sharedPortfolio: any;
}
interface IUserActivePlan
{
    cancelled: boolean;
    fnmContractId: number;
    finametrixContract: string;
    outdated: boolean;
    outdatedContract: boolean;
    planId: number;
    planName: string;
    portfolio: PortfolioItem;
    status: number;
    type: number;
    userId: number;
    userSubscriptionId: number;
    allUserIdentitiesHaveSuitabilityTest: boolean;
}

export type UserActive = IUserActivePlan;

class UserService
{
    public getUser = async (userId: number): Promise<UserType> =>
    {
        let url: string = `${proxy}/users/${userId}`;
        const req = await axios.get<UserType>(url);
        return req.data;
    };
    public getUserByDruid = async (userDruId: string): Promise<UserType> =>
    {
        let url: string = `${proxy}/users/getByDruId/${userDruId}`;
        const req = await axios.get<UserType>(url);
        return req.data;
    };
    public getUserLastActivePlan = async (userId: any): Promise<UserActive> =>
    {
        let url: string = `${proxy}/getActivePlan/${userId}`;
        const req = await axios.get<UserActive>(url);
        return req.data;
    };
    public createUser = async (user: UserType): Promise<UserType> =>
    {
        let url: string = `${proxy}/users/${user.id}`;
        const req = await axios.post(url, user);
        return req.data;
    };
    public updateUser = async (user: UserType): Promise<UserType> =>
    {
        let url: string = `${proxy}/users/${user.id}`;
        const req = await axios.patch(url, user);
        return req.data;
    };
    public getSuscriptions = async (userId: number): Promise<SubscriptionType[]> =>
    {
        let url: string = `${proxy}/users/${userId}/subscriptions`;
        const req = await axios.get(url);
        return req.data;
    };
    public getBankAccountsAutorized = async (userId: number): Promise<AccountsAutorized[]> =>
    {
        let url: string = `${proxy}/user/${userId}/accounts`;
        const req = await axios.get(url);
        return req.data;
    };
    public getBankAccountsAuthorizedBySavingPlanId = async (userId: number, savingPlanId: number): Promise<AccountsAutorized[]> =>
    {
        let url: string = `${proxy}/UserAccountAuthorizedBySavingPlanId/${savingPlanId}/${userId}`;
        const req = await axios.get(url);
        return req.data;
    };
    public getBankAccountsUsed = async (userId: number): Promise<AccountsUsed[]> =>
    {
        let url: string = `${proxy}/user/${userId}/accounts/used`;
        const req = await axios.get(url);
        return req.data;
    };

    public getBankAccountsUsedWithParam = async (userId: number, param: string): Promise<AccountsUsed[]> =>
        {
            let url: string = `${proxy}/user/${userId}/accounts/used?jwt=${param}`;
            const req = await axios.get(url);
            return req.data;
        };

    public postBankAccount = async (toSend: FormData, userId: number): Promise<AccountsUsed[]> =>
    {
        let url: string = `${proxy}/user/${userId}/accounts`;
        const req = await axios.post<any>(url, toSend);
        return req.data;
    };
    public selectBankAccount = async (toSend: FormData, userId: number): Promise<AccountsUsed[]> =>
    {
        let url: string = `${proxy}/user/${userId}/accounts`;
        const req = await axios.patch<any>(url, toSend);
        return req.data;
    };
    public deleteBankAccount = async (bankAccountId: number, userId: number): Promise<AccountsType[]> =>
    {
        let url: string = `${proxy}/user/${userId}/accounts?idUserBankAccount=${encodeURIComponent(bankAccountId)}`;
        const req = await axios.delete(url);
        return req.data;
    };
    public dowloadBase64Pdf = async (bankAccountId: number, userId: number): Promise<any> =>
    {
        let url: string = `${proxy}/user/${userId}/accounts/authorized/document?idUserBankAccount=${encodeURIComponent(bankAccountId)}`;
        const req = await axios.get<any>(url, {responseType: 'blob'});
        const reader = new FileReader();
        return new Promise((resolve) =>
        {
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(req.data);
        });
    };
    public selectActivePlan = async (userId: number, planId: number): Promise<boolean> =>
    {
        let url: string = `${proxy}/updateActivePlan/${userId}/${planId}`;
        const req = await axios.patch(url);
        return req.data;
    };
    public selectActivePlanByName = async (userId: number, planName: string): Promise<boolean> =>
    {
        let url: string = `${proxy}/updateActivePlanByName/${userId}/${planName}`;
        const req = await axios.patch(url);
        return req.data;
    };
    public setConfigurationMap = async (userId: number, value: boolean): Promise<boolean> =>
    {
        let url: string = `${proxy}/setConfigurationMap/${userId}/${value}`;
        const req = await axios.patch(url);
        return req.data;
    };
}

const users = new UserService();
export default users;
import { useCallback, useContext } from "react";
import { TableWrapper, TitleContainer, DateContainer, WarningContainer } from "./LayoutStyles";
import Table from "../../../components/tables/GenericTable";
import { Separator, Spinner, SpinnerSize } from "@fluentui/react";
import { ThemeContext } from "@fluentui/react-theme-provider";
import { DatePicker } from "@fluentui/react";
import moment from "moment";
import Auth from "../../../Auth/Auth";
import FinametrixService from "../../../services/FinametrixService";
import DocumentTitle from "react-document-title";
import GenericTableFiscalReportDead from "../../../components/tables/GenericTable/FiscalReportActiva";
import { PrimaryButton } from "office-ui-fabric-react";
import { DayPickerStrings } from "../../../components/ConvenienceTest/Utils/DatesTranlateFluents";
import config from "../../../config";
import { ChevronDownIcon } from "@fluentui/react-icons-mdl2";
import React from "react";
import { Redirect } from "react-router";
import { decimalFormatter, longDecimalFormatter } from "../../../utils/numberFormatter";

const FiscalReport = () =>
{
    let activePlan = Auth.getActivePlan();
    const theme = useContext(ThemeContext) || {palette: {accent: ''}};
    const currentDate = new Date();
    const firstDay = new Date(currentDate.getFullYear(), 0, 1);
    const [isLoadingData, setIsLoadingData] = React.useState<boolean>(false);

    const [dates, setDates] = React.useState(
    {
        from:
        {
            raw: firstDay,
            formatted: moment(firstDay).format("DD/MM/YYYY"),
        },
        to:
        {
            raw: currentDate,
            formatted: moment(currentDate).format("DD/MM/YYYY"),
        },
    });
    const [error, setError] = React.useState({message: "", status: 0});
    const [fiscalReport, setFiscalReport] = React.useState({fiscalItems: [], closedPositions: [], closedFicalItems: []});
    const [resultImporteActivos, setResultImporteActivos] = React.useState<string[]>(["Total", "-", "-", "-", `-`, `-`, `-`]);
    const [resumenImportesCerrados, setResumenImportesCerrados] = React.useState<string[]>(["Total", "-", "-", "-", "-", "-", "-", "-", "-"]);
    const subscribed = Auth.isSubscribed();
    const outdated = Auth.isLastPlanOutdated();
    const cancelled = Auth.isActivePlanCancelled();

    const callApiReportingFiscal = useCallback(async (dates) =>
    {
        setIsLoadingData(true);
        FinametrixService.getFiscalItems(activePlan.portfolio.finametrixId, convertDateToText(dates.from.raw), convertDateToText(dates.to.raw)).then((data) =>
        {
            setFiscalReport(data);

            let resultImporteAct = data.fiscalItems.length > 0 ? data.fiscalItems.reduce((a: any, b: any) => ({buy_amount: a.buy_amount + b.buy_amount})) : null;
            let resultCurrenValActivos = data.fiscalItems.length > 0 ? data.fiscalItems.reduce((a: any, b: any) => ({current_value: a.current_value + b.current_value})) : null;
            let resultProfitActivos = data.fiscalItems.length > 0 ? data.fiscalItems.reduce((a: any, b: any) => ({profit: a.profit + b.profit})) : null;
            let resumen =
            [
                "Total",
                "-",
                "-",
                "-",
                resultImporteAct !== null ? `${decimalFormatter.format(parseFloat(parseFloat(resultImporteAct.buy_amount).toFixed(2)))} €` : '',
                resultCurrenValActivos !== null ? `${decimalFormatter.format(parseFloat(parseFloat(resultCurrenValActivos.current_value).toFixed(2)))} €` : '',
                resultProfitActivos !== null ? `${decimalFormatter.format(parseFloat(parseFloat(resultProfitActivos.profit).toFixed(2)))} €`: ''
            ];
            setResultImporteActivos(resumen);

            let resultImporteCerrado = data.closedPositions.length > 0 ? data.closedPositions.reduce((a: any, b: any) => ({subscription_price: a.subscription_price + b.subscription_price})) : null;
            let resultRedenValCerrado = data.closedPositions.length > 0 ? data.closedPositions.reduce((a: any, b: any) => ({redemption_price: a.redemption_price + b.redemption_price})) : null;
            let resultProfitCerrado = data.closedPositions.length > 0 ? data.closedPositions.reduce((a: any, b: any) => ({ profit: a.profit + b.profit })) : null;
            let resultRetentionCerrado = data.closedPositions.length > 0 ? data.closedPositions.reduce((a: any, b: any) => ({retention: a.retention + b.retention})) : null;
            let resumenCerrados =
            [
                "Total",
                "-",
                "-",
                "-",
                "-",
                resultImporteCerrado !== null ? `${decimalFormatter.format(parseFloat(parseFloat(resultImporteCerrado.subscription_price).toFixed(2)))} €` : '',
                resultRedenValCerrado !== null ? `${decimalFormatter.format(parseFloat(parseFloat(resultRedenValCerrado.redemption_price).toFixed(2)))} €` : '',
                resultProfitCerrado !== null ? `${decimalFormatter.format(parseFloat(parseFloat(resultProfitCerrado.profit).toFixed(2)))} €` : '',
                resultRetentionCerrado !== null ? `${decimalFormatter.format(parseFloat(parseFloat(resultRetentionCerrado.retention).toFixed(2)))} €` : ''
            ];
            setResumenImportesCerrados(resumenCerrados);
            setIsLoadingData(false);
        },
        (error) =>
        {
            setIsLoadingData(false);
            setError(error);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() =>
    {
        callApiReportingFiscal(dates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePlan.portfolio.finametrixId, callApiReportingFiscal]);

    const convertDateToText = (date: any) =>
    {
        let dateForma = moment(date).format("YYYY/MM/DD")
        dateForma = dateForma.replaceAll("/", "")
        return dateForma
    }
    if(subscribed === false)
    {
        localStorage.clear();
        window.location.href = config.server_host + '/login';
        return (<></>);
    }
    if(outdated === true || cancelled === true)
    {
        return <Redirect to="/savingsPlans" />;
    }
    if(error.status)
    {
        return(
            <DocumentTitle title="IronIA - Resumen de cartera">
                <div>Error: {error.message}</div>
            </DocumentTitle>
        );
    }

    return(
        <DocumentTitle title="IronIA - Resumen de cartera">
            <React.Fragment>
                <TitleContainer style={{marginTop: '10px', marginBottom: '20px'}}>
                    <h1>Información fiscal</h1>
                </TitleContainer>
                <DateContainer theme={theme}>
                    <div>
                        <span>Desde</span>
                        <DatePicker styles={{icon: {color: theme.palette.accent}}}
                            value={dates.from.raw}
                            strings={DayPickerStrings}
                            formatDate={(date) =>
                            {
                                return moment(date).format('DD/MM/YYYY');
                            }}
                            onSelectDate={(value: any) =>
                            {
                                setDates(
                                {
                                    ...dates,
                                    from:
                                    {
                                        raw: value,
                                        formatted: moment(value).format("DD/MM/YYYY")
                                    },
                                });
                            }}
                            allowTextInput={true}
                            parseDateFromString={(dateStr) =>
                            {
                                var parts: string[] = dateStr.split('/');
                                var myDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
                                return new Date(myDate)
                            }}
                        />
                    </div>
                    <div>
                        <span>Hasta</span>
                        <DatePicker styles={{icon: {color: theme.palette.accent}}}
                            value={dates.to.raw}
                            strings={DayPickerStrings}
                            maxDate={new Date(Date.now())}
                            formatDate={(date) =>
                            {
                                return moment(date).format('DD/MM/YYYY');
                            }}
                            onSelectDate={(value: any) =>
                            {
                                setDates(
                                {
                                    ...dates,
                                    to:
                                    {
                                        raw: value,
                                        formatted: moment(value).format("DD/MM/YYYY")
                                    },
                                });
                            }}
                            allowTextInput={true}
                            parseDateFromString={(dateStr) =>
                            {
                                var parts: string[] = dateStr.split('/');
                                var myDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
                                return new Date(myDate)
                            }}
                        />
                    </div>
                    <div style={{marginTop: 19}} className='ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl3 ms-xxl2'>
                        <PrimaryButton onClick={() => callApiReportingFiscal(dates)} className='rounded'>
                            Buscar
                        </PrimaryButton>
                    </div>
                </DateContainer>
                <Separator />
                <TableWrapper theme={theme}>
                    {isLoadingData && <Spinner size={SpinnerSize.large} />}
                    {!isLoadingData &&
                    (
                        <Table counterText='Partidas fiscales vivas'
                            data={fiscalReport.fiscalItems && fiscalReport.fiscalItems.map((row: any) =>
                            {
                                return{
                                    ...row,
                                    subData:
                                    {
                                        data: row.fiscalItems,
                                        columns: fiscalReportSubtableColumns,
                                    },
                                };
                            })}
                            columns={columns}
                            resumeData={resultImporteActivos.length >= 1 ? resultImporteActivos : []}
                        />
                    )}
                </TableWrapper>
                <TableWrapper theme={theme}>
                    {isLoadingData && <Spinner size={SpinnerSize.large} />}
                    {!isLoadingData &&
                    (
                        <GenericTableFiscalReportDead counterText='Partidas fiscales cerradas'
                            data={fiscalReport.closedPositions && fiscalReport.closedPositions.map((row: any) =>
                            {
                                return{
                                    ...row,
                                    subData:
                                    {
                                        data: row.closedFicalItems,
                                        columns: columnsCloseSubtablas,
                                    },
                                };
                            })}
                            columns={columnsClose}
                            resumeData={resumenImportesCerrados}
                        />
                    )}
                </TableWrapper>
                <WarningContainer theme={theme}>
                    <div style={{display: "flex", cursor: "default"}}>
                        <img src='/icons/information.svg' alt="Información" width={23} />
                        <h3 style={{paddingLeft: "1em", width: "95%"}}>Aviso legal</h3>
                    </div>
                    <p>
                        A efectos de facilitarle la toma de decisiones para optimizar su fiscalidad le
                        mostramos a continuación los datos de las partidas fiscales de las que tenemos
                        información. El presente documento no constituye certificación alguna por
                        parte de IronIA. Únicamente debe considerarse como un mero documento
                        informativo con el que se pretende facilitarle el cumplimiento de sus
                        obligaciones fiscales. Tenga en cuenta que si dispone de otra inversiones en
                        otras entidades esta información no estará completa. Estas partidas fiscales
                        se encuentran ordenadas por fondo de inversión de mayor a menor plusvalía y
                        dentro de cada fondo siguiente el criterio oficial FIFO (First in – First
                        out). La información se muestra siempre en euros ya que es la divisa de
                        referencia para la Hacienda española.
                    </p>
                </WarningContainer>
                <WarningContainer theme={theme} style={{marginTop: "1em"}}>
                    <div style={{display: "flex", cursor: "default"}}>
                        <img src='/icons/information.svg' alt="Información" width={22} />
                        <h3 style={{paddingLeft: "1em", width: "95%"}}>Retenciones</h3>
                    </div>
                    <p>
                        Todas las retenciones negativas suponen una minusvalía en la operación, por lo cual no se realizará retención alguna en los reembolsos.
                    </p>
                </WarningContainer>
            </React.Fragment>
        </DocumentTitle>
    );
};

export default FiscalReport;

const columns = [
{
    id: "name",
    title: "Nombre",
    source: "name",
    customCell: (item: any) =>
    (
        <div><ChevronDownIcon /> {item.value}</div>
    )
},
{
    id: "name",
    title: "ISIN",
    source: "isin",
    customCell: (item: any) =>
    (
        <div>{item.value}</div>
    )
},
{
    id: "Fecha",
    title: "Fecha",
    source: "creationDate",
    customCell: () =>
    (
        <div>-</div>
    )
},
{
    id: "Titulos",
    title: "Títulos",
    source: "shares",
    customCell: (item: any) =>
    (
        <div>{longDecimalFormatter.format(item.value)}</div>
    )
},
{
    id: "Importe de compra",
    title: "Importe de compra",
    source: "buy_amount",
    customCell: (item: any) =>
    (
        <div>{decimalFormatter.format(parseFloat(parseFloat(item.value).toFixed(2)))} €</div>
    )
},
{
    id: "Valor Actual",
    title: "Valor Actual",
    source: "current_value",
    customCell: (item: any) =>
    (
        <div>{decimalFormatter.format(parseFloat(parseFloat(item.value).toFixed(2)))} €</div>
    )
},
{
    id: "Plusvalia / minusvalia latente",
    title: "Plusvalía / Minusvalía latente",
    source: "profit",
    customCell: (item: any) =>
    (
        <div>{decimalFormatter.format(parseFloat(parseFloat(item.value).toFixed(2)))} €</div>
    )
}];
const columnsClose = [
{
    id: "name",
    title: "Nombre",
    source: "name",
    customCell: (item: any) =>
    (
        <div>{item.value}</div>
    )
},
{
    id: "name",
    title: "ISIN",
    source: "isin",
    customCell: (item: any) =>
    {
        return <div>{item.value}</div>;
    },
},
{
    id: "Fecha",
    title: "Fecha de compra",
    source: "subscriptionDateAsString",
    customCell: (item: any) =>
    {
        return(
            <div>
                {item.row.closedFicalItems !== undefined && item.row.closedFicalItems.length > 0 ? item.row.closedFicalItems[0].subscriptionDateAsString : '-'}
            </div>
        )
    }
},
{
    id: "Fecha2",
    title: "Fecha de venta",
    source: "redemptionDateAsString",
    customCell: (item: any) =>
    (
        <div>
            {item.row.closedFicalItems !== undefined && item.row.closedFicalItems.length > 0 ? item.row.closedFicalItems[0].redemptionDateAsString : '-'}
        </div>
    )
},
{
    id: "Titulos",
    title: "Títulos",
    source: "shares",
    customCell: (item: any) =>
    (
        <div>{longDecimalFormatter.format(item.value)}</div>
    )
},
{
    id: "Importe de compra",
    title: "Importe de compra",
    source: "subscription_price",
    customCell: (item: any) =>
    (
        <div>{decimalFormatter.format(parseFloat(parseFloat(item.value).toFixed(2)))} €</div>
    )
},
{
    id: "Importe de venta",
    title: "Importe de venta",
    source: "redemption_price",
    customCell: (item: any) =>
    (
        <div>{decimalFormatter.format(parseFloat(parseFloat(item.value).toFixed(2)))} €</div>
    )
},
{
    id: "Plusvalia / minusvalia cerrada",
    title: "Plusvalía / Minusvalía cerrada",
    source: "profit",
    customCell: (item: any) =>
    (
        <div>{decimalFormatter.format(parseFloat(parseFloat(item.value).toFixed(2)))} €</div>
    )
},
{
    id: "Retención",
    title: "Retención (*)",
    source: "retention",
    customCell: (item: any) =>
    (
        <div>{decimalFormatter.format(parseFloat(parseFloat(item.value).toFixed(2)))} €</div>
    )
}];
const columnsCloseSubtablas =[
{
    id: "name",
    title: "Nombre",
    source: "name",
    customCell: (item: any) =>
    {
        return <div>{item.value}</div>;
    },
},
{
    id: "name",
    title: "ISIN",
    source: "isin",
    customCell: (item: any) =>
    {
        return <div>{item.value}</div>;
    },
},
{
    id: "Fecha",
    title: "Fecha de compra",
    source: "subscriptionDateAsString",
    customCell: (item: any) =>
    {
        return <div>{item.value}</div>;
    },
},
{
    id: "Fecha2",
    title: "Fecha de venta",
    source: "redemptionDateAsString",
    customCell: (item: any) =>
    {
        return <div>{item.value}</div>;
    },
},
{
    id: "Titulos",
    title: "Títulos",
    source: "shares",
    customCell: (item: any) =>
    {
        return <div>{longDecimalFormatter.format(item.value)}</div>;
    }
},
{
    id: "Importe de compra",
    title: "Importe de compra",
    source: "subscription_price",
    customCell: (item: any) =>
    {
        return <div>{decimalFormatter.format(parseFloat(parseFloat(item.value).toFixed(2)))} €</div>;
    },
},
{
    id: "Importe de compra",
    title: "Importe de compra",
    source: "redemption_price",
    customCell: (item: any) =>
    {
        return <div>{decimalFormatter.format(parseFloat(parseFloat(item.value).toFixed(2)))} €</div>;
    },
},
{
    id: "Plusvalia / minusvalia latente",
    title: "Plusvalía / Minusvalía latente",
    source: "profit",
    customCell: (item: any) =>
    {
        return <div>{decimalFormatter.format(parseFloat(parseFloat(item.value).toFixed(2)))} €</div>;
    },
},
{
    id: "Retención",
    title: "Retención",
    source: "retention",
    customCell: (item: any) =>
    {
        return <div>{decimalFormatter.format(parseFloat(parseFloat(item.value).toFixed(2)))} €</div>;
    },
}];
const fiscalReportSubtableColumns = [
{
    id: "creationDate",
    title: "creationDate",
    source: "creationDate",
    customCell: (item: any) =>
    {
        return <div>{item.value}</div>;
    }
},
{
    id: "shares",
    title: "shares",
    source: "shares",
    customCell: (item: any) =>
    {
        return <div>{longDecimalFormatter.format(item.value)}</div>;
    },
},
{
    id: "amount",
    title: "amount",
    source: "amount",
    customCell: (item: any) =>
    {
        return <div>{decimalFormatter.format(parseFloat(parseFloat(item.value).toFixed(2)))} €</div>;
    },
},
{
    id: "current_value",
    title: "current_value",
    source: "current_value",
    customCell: (item: any) =>
    {
        return <div>{decimalFormatter.format(parseFloat(parseFloat(item.value).toFixed(2)))} €</div>;
    },
},
{
    id: "profit",
    title: "profit",
    source: "profit",
    customCell: (item: any) =>
    {
        return <div>{decimalFormatter.format(parseFloat(parseFloat(item.value).toFixed(2)))} €</div>;
    },
}];